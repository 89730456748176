# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/

$(document).on('turbolinks:load', () ->
  $('.radio-with-other input.string').on 'focus', ->
    $.each $('input[type="radio"]'), (_, input) ->
      $(input).prop('checked', false)

  $('.radio-with-other input[type="radio"]').on 'focus', ->
    $('.radio-with-other input.string').val('')
)
