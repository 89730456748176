const loadPanelContent = (panelSelect) => {
  if (panelSelect.length == 0) return

  const selectedOption = panelSelect.find('option:selected')
  const urlToCall = panelSelect.data('panel_content_path') + window.location.search
  const panelData = {
    s: panelSelect.data('s'),
    utm_source: panelSelect.data('utm_source'),
    utm_campaign: panelSelect.data('utm_campaign'),
    public_invitation_code: panelSelect.data('public_invitation_code'),
    panel: selectedOption.data('panel-code'),
    country: selectedOption.data('country-code'),
    language: selectedOption.data('locale-code')
  }

  $.ajax({
    url: urlToCall,
    type: 'get',
    cache: false,
    data: panelData,
    dataType: 'script',
    statusCode: {
      302: function(response) {
        var redirect_url = response.getResponseHeader('X-Ajax-Redirect-Url')
        if (redirect_url != undefined) {
            window.location.href = redirect_url
        }
      }
    }
  })
}

const setSelectListener = (panelSelect) => {
  panelSelect.on('loaded.bs.select', () => loadPanelContent(panelSelect))
  panelSelect.on('changed.bs.select', () => loadPanelContent(panelSelect))
}

$(document).on('turbolinks:load', () => {
  $('#panels').selectpicker()
  setSelectListener($('#panels'))
})