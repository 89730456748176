checkOrUncheckAll = -> 
  acceptAll = $('#consents_accept_all')
  privacyItems = $('.privacy-items input[type="checkbox"]:not(#consents_accept_all)')
  acceptAll.change ->
    is_checked = this.checked
    privacyItems.each (i, item) ->
      item.checked = is_checked
      return
  privacyItems.change ->
    setAcceptAll()

setAcceptAll = ->
  acceptAll = $('#consents_accept_all')
  privacyItems = $('.privacy-items input[type="checkbox"]:not(#consents_accept_all)')
  numberNotChecked = privacyItems.filter(':not(:checked)').length
  unless acceptAll.length == 0
    if numberNotChecked == 0
      acceptAll[0].checked = true
    else if numberNotChecked > 0
      acceptAll[0].checked = false

module.exports = {
  checkOrUncheckAll,
  setAcceptAll
}
