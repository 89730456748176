import { setAcceptAll, checkOrUncheckAll } from './consents_accept_all'

initializeJsFields = ->
  # avoid double form submission
  $('form').submit ->
    $("input[type='submit']").prop('disabled',true)

  # initialize privacy fields
  setAcceptAll()
  checkOrUncheckAll()

  # initialize datepicker
  birthdate = $('input#panelist_birthdate').data('value')
  $('#panelist_birthdate').datetimepicker({
    date: moment(birthdate, 'DD/MM/YYYY'),
    viewMode: 'years',
    format: 'DD/MM/YYYY'
  })

window.initializeJsFields = initializeJsFields

$(document).on('turbolinks:load', () ->
  initializeJsFields()
)
