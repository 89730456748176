showNoSurveysMessageIfNeeded = () ->
  if $('.survey-card').length == 0
    $('#noSurveysMessage').show()

loadSurveys = () ->
  if document.getElementById('surveysLoader')
    $('#surveySpinner').show()
    urlToCall = $('#surveysLoader').data('surveys_fetcher_path')
    $.ajax(
      url: urlToCall,
      type: 'get',
      success: () -> showNoSurveysMessageIfNeeded(),
      error: () ->
        $('#surveySpinner').hide()
        showNoSurveysMessageIfNeeded()
    )

initializeLoadMoreButton = () ->
  $('#loadMoreSurveys').on('click', () ->
    $(this).hide()
    $('#surveySpinner').show()
    urlToCall = $(this).data('surveys_fetcher_path')
    $.ajax(
      url: urlToCall,
      type: 'get',
      success: () -> showNoSurveysMessageIfNeeded(),
      error: () ->
        $('#surveySpinner').hide()
        showNoSurveysMessageIfNeeded()
    )
  )

$(document).on('turbolinks:load', () ->
  # console.log('turbolinks:load event fired')
  # console.log('Current URL: ' + window.location.href)
  # console.trace()
  loadSurveys()
  initializeLoadMoreButton()
)
