class Gtag {
  static get GRANTED() {
    return 'granted';
  }

  static get DENIED() {
    return 'denied';
  }

  pushToDataLayer() {
    window.dataLayer.push(arguments)
  }

  pushInitialConsents() {
    this.pushToDataLayer(
      'consent',
      'default',
      {
        ad_storage: this.constructor.DENIED,
        ad_user_data: this.constructor.DENIED,
        ad_personalization: this.constructor.DENIED,
        analytics_storage: this.constructor.GRANTED,
      }
    )

    this.pushToDataLayer(
      {
        'event': 'default_consent'
      }
    )
  }

  pushUpdatedConsent(consentName, isGranted) {
    this.pushToDataLayer(
      'consent',
      'update',
      {
        [consentName]: isGranted ? this.constructor.GRANTED : this.constructor.DENIED
      }
    )
  }

  updateConsentByCookieName(cookieName, isCookieAccepted) {
    switch(cookieName) {
      case "analytics_cookies_accepted":
        this.pushUpdatedConsent('analytics_storage', isCookieAccepted);
        break;
      case "marketing_cookies_accepted":
        this.pushUpdatedConsent('ad_storage', isCookieAccepted);
        this.pushUpdatedConsent('ad_user_data', isCookieAccepted);
        this.pushUpdatedConsent('ad_personalization', isCookieAccepted);
        break;
      default:
        break;
    }
  }
}

export default Gtag;
