import RailsUjs from '@rails/ujs'
import Turbolinks from 'turbolinks'

RailsUjs.start()
Turbolinks.start()

// core js modules
import 'bootstrap'
import 'tempusdominus-bootstrap-4'
import 'bootstrap-select'
import 'jasny-bootstrap/js/fileinput'
import Gtag from './gtag'

// monkey patch to fire turbolinks event on page reloaded
// `$(document).on('ready turbolinks:load')` does not work
// https://github.com/turbolinks/turbolinks/issues/62
$(document).ready(() => {
  if (Turbolinks.controller.lastRenderedLocation.isCached) {
    const fakeTurbolinksEvent = new Event('turbolinks:load')
    document.dispatchEvent(fakeTurbolinksEvent)
  }
})

window.gtagManager = new Gtag()
